import * as React from 'react';

type Props = {
  className?: string;
};

const InformationIcon: React.FC<Props> = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    overflow="visible"
    width="64px"
    height="64px"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M32,5.125 C39.4218733,5.125 45.76953,7.76172 51.04297,13.03516 C56.3164033,18.3085933 58.95312,24.6562467 58.95312,32.07812 C58.95312,39.4999933 56.3164033,45.8476467 51.04297,51.12108 C45.76953,56.39452 39.4218733,59.03124 32,59.03124 C24.5781267,59.03124 18.23047,56.39452 12.95703,51.12108 C7.68359,45.8476467 5.04688,39.4999933 5.04688,32.07812 C5.04688,24.6562467 7.68359667,18.3085933 12.95703,13.03516 C18.23047,7.76172 24.5781267,5.125 32,5.125 Z M32,2 C23.6666667,2 16.5703133,4.92968667 10.71094,10.78906 C4.85156667,16.6484333 1.92188,23.7447867 1.92188,32.07812 C1.92188,40.4114533 4.85156,47.5078067 10.71094,53.36718 C16.5703133,59.2265533 23.6666667,62.15624 32,62.15624 C40.3333333,62.15624 47.4296867,59.2265533 53.28906,53.36718 C59.1484333,47.5078067 62.07812,40.4114533 62.07812,32.07812 C62.07812,23.7447867 59.1484333,16.6484333 53.28906,10.78906 C47.4296867,4.92968667 40.3333333,2 32,2 Z M32,47.89843 C30.3072933,47.89843 29.46094,47.0520767 29.46094,45.35937 C29.46094,40.41145 29.46094,34.22656 29.46094,30.51562 C29.46094,28.8229133 30.3072933,27.97656 32,27.97656 C33.6927067,27.97656 34.53906,28.8229133 34.53906,30.51562 C34.53906,35.46354 34.53906,41.64843 34.53906,45.35937 C34.53906,47.0520767 33.6927067,47.89843 32,47.89843 Z M34.53906,21.33593 C34.53906,23.15885 33.6927067,24.07031 32,24.07031 C30.3072933,24.07031 29.46094,23.15885 29.46094,21.33593 C29.46094,19.6432233 30.3072933,18.79687 32,18.79687 C33.6927067,18.79687 34.53906,19.6432233 34.53906,21.33593 Z"
        fill="#1A1919"
      />
    </g>
  </svg>
);

export default InformationIcon;
